var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"sm":"12","cols":"12"}},[_c('div',{staticStyle:{"background-color":"#fff","padding":"20px 20px","border-radius":"10px"}},[_c('v-col',{attrs:{"sm":"12","cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"sm":"6","cols":"12"}},[_c('v-snackbar',{attrs:{"color":_vm.snackbar.color,"right":""},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")]),_c('v-btn',{staticClass:"mx-2",attrs:{"depressed":"","small":"","color":"deep-purple lighten-2","dark":"","mdi-keyboard-backspace":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-keyboard-backspace ")]),_vm._v(" Back")],1)],1)],1),_c('div',{staticStyle:{"margin-top":"20px"}}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"sm":"12","cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"sm":"6","cols":"12","md":""}},[_c('div',{staticClass:"text-h5"},[_vm._v("Result Of the Exam")]),_c('div',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.academic_year[0])+" ")])])],1),_c('v-row',[_c('v-col',{attrs:{"sm":"6","cols":"12"}},[_c('div',{staticClass:"float-left text-h7"},[_vm._v(" Class: "+_vm._s(_vm.major[0])+" ")])])],1),_c('v-card',[[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.listHeader,"items":_vm.listStudent,"hide-default-header":"","hide-default-footer":"","sort-by":_vm.sortBy,"disable-pagination":"","sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('thead',{staticStyle:{"white-space":"nowrap"}},[_c('tr',[_c('th',{staticStyle:{"text-align":"center"},attrs:{"colspan":_vm.listHeader.length + 3}},[_vm._v(" "+_vm._s(_vm.major_gen[0])+" ")])]),_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v("No")]),_vm._l((props.headers),function(header){return _c('th',{key:header.text,staticClass:"text-center",class:[
                                'column sortable',
                                _vm.sortDesc ? 'desc' : 'asc',
                                header.value === _vm.sortBy ? 'active' : '' ]},[(header.text == 'Student ID')?_c('span',{staticStyle:{"text-align":"center"}},[_c('th',{attrs:{"rowspan":"2"}},[_vm._v(_vm._s(header.text))])]):(header.text == 'Student Name')?_c('span',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(header.text))]):(header.text == 'Total Score')?_c('span',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(header.text)+" ")]):(header.text == 'Rank')?_c('span',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(header.text)+" ")]):(header.text == 'GPA')?_c('span',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(header.text)+" ")]):(header.text == 'MARK')?_c('span',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(header.text)+" ")]):_c('span',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(header.text)),_c('br'),_vm._v(" (Credit:"+_vm._s(header.credit)+")")]),(_vm.sortDesc)?_c('v-icon',{attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.changeSort(header.value)}}},[_vm._v("arrow_upward")]):_c('v-icon',{attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.changeSort(header.value)}}},[_vm._v("arrow_downward")])],1)}),_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("action"))+" ")])],2)])]}},{key:"body",fn:function(props){return [_c('tbody',{staticStyle:{"white-space":"nowrap"}},_vm._l((props.items),function(index,i){return _c('tr',{key:i,class:_vm.approvedStatus(index.student_id) != null
                                ? 'deep-purple lighten-5'
                                : 'white',on:{"click":function($event){return _vm.$router.push({
                                name: 'MajorAdminTranscript',
                                params: {
                                  student_id: index.student_id,
                                  annual_id: _vm.annual_id,
                                },
                              })}}},[_c('td',{staticClass:"text-center font-weight-black",staticStyle:{"height":"35px","border":"1pt solid rgb(104, 102, 102) !important"}},[_vm._v(" "+_vm._s(1 + i++)+" ")]),_vm._l((_vm.listHeader),function(header,indx){return _c('td',{key:indx,staticClass:"text-center font-weight-black",staticStyle:{"height":"35px","border":"1pt solid rgb(104, 102, 102) !important"}},[_c('span',[(indx == 0)?_c('span',{staticClass:"float-left"},[_vm._v(" "+_vm._s(index[header.value]))]):(indx == 1)?_c('span',{staticClass:"float-left"},[_vm._v(" "+_vm._s(index[header.value]))]):_c('span',{staticClass:"text-center"},[_vm._v(" "+_vm._s(index[header.value]))])])])}),_c('td',{staticClass:"text-center",staticStyle:{"border":"1pt solid rgb(104, 102, 102) !important"}},[_c('v-btn',{staticClass:"rounded-pill",attrs:{"max-height":"30","x-small":"","color":"deep-purple lighten-1","dark":""},on:{"click":function($event){return _vm.$router.push({
                                    name: 'MajorAdminTranscript',
                                    params: {
                                      student_id: index.student_id,
                                      annual_id: _vm.annual_id,
                                    },
                                  })}}},[_c('span',{staticClass:"text-capitalize"},[_vm._v(" VIEW TRANS. ")])])],1)],2)}),0)]}}])})]],2)],1)],1)],1)],1)])],1)],1),_c('div',[_c('div',{staticClass:"loading"},[_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":_vm.fullPage,"opacity":0.9,"background-color":"#dedede","width":40,"height":40},on:{"update:active":function($event){_vm.isLoading=$event}}},[(_vm.myLoading)?_c('div',[_c('img',{attrs:{"width":"100%","src":"https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Floading.gif?alt=media&token=58553b10-7335-42a6-a0c8-8a6d55927816"}})]):_vm._e()])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }